module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Grayscale","short_name":"Home","start_url":"/gatsby-starter-grayscale/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/img/roddenvo-w-buff.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
